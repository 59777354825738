import Vuex from "vuex";
import Vue from "vue";
import NotificationService from "@/services/Notification";
import Wallet from "@/services/Wallet";
import createPersistedState from "vuex-persistedstate";
import { fetchWidgetInfo, saveWidgetInfo, fetchPwaInfo, savePwaInfo } from "@/services/Apps";
import ComplianceService from "@/services/Compliance";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    notifications: [],
    user: {},
    wallets: [],
    paymentGateway: "",
    widgetConfig: {},
    pwaConfig: {},
    compliance:{},
    loader: false,
    walletNUBAN: null,
    partner: null,
    isLoading: true,
    insurer: null
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    addNotification(state, notification) {
      state.notifications.unshift(notification);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("grow_user", JSON.stringify(user));
    },
    setWallets(state, wallets) {
      state.wallets = wallets;
    },
    setPaymentGateway(state, gateWay) {
      state.paymentGateway = gateWay;
    },
    setWidgetConfig(state, config) {
      state.widgetConfig = config;
    },
    setPwaConfig(state, config) {
      state.pwaConfig = config;
    },
    setCompliance(state, compliance){
      state.compliance = compliance;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setLoaderState(state, payload) {
        state.isLoading = payload;
    },
    setWalletNUBAN(state, data) {
      state.walletNUBAN = data;
    },
    setPartner(state, partner) {
      state.partner = partner
    },
    setInsurer(state, insurer) {
      state.insurer = insurer
    }
  },
  actions: {
    async fetchNotifications({ commit }) {
      try {
        const response = await NotificationService.list();
        commit("setNotifications", response.data);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async fetchWalletBalance({ commit }) {
      try {
        const response = await Wallet.getBalance();
        commit("setWallets", response.data);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async loadWidgetConfig({ commit }) {
      try {
        const resp = await fetchWidgetInfo();
        commit("setWidgetConfig", resp.data);
        return resp;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async loadPwaConfig({ commit }) {
      try {
        const resp = await fetchPwaInfo();
        commit("setPwaConfig", resp.data);
        return resp;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async savePwaConfig({ commit, state }, payload) {
      try {
        const resp = await savePwaInfo(payload);
        commit("setPwaConfig", resp.data);

        if (payload.create_url) {
          const partner = {
            ...state.partner,
            pwa_slug: resp.data.slug
          }

          commit('setPartner', partner);
        }

        return Promise.resolve(resp);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async saveWidgetConfig({ commit, state }) {
      try {
        const resp = await saveWidgetInfo(state.widgetConfig);
        commit("setWidgetConfig", resp.data);
        return resp;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async fetchCompliance({ commit }) {
      try {
        const response = await ComplianceService.getCompliance();
        commit("setCompliance", response.data.data);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async fetchWalletNUBAN({commit}) {
      try {
        const response = await Wallet.fetchNUBAN();
        commit('setWalletNUBAN', response.data.data);
        return response;
      } catch (e) {
        if (!e.response) {
          console.log(e);
        }
        return e;
      }
    }
  },
  getters: {
    notifications: state => state.notifications,
    user: state => state.user,
    wallets: state => state.wallets,
    paymentGateway: state => state.paymentGateway,
    widgetConfig: state => state.widgetConfig,
    pwaConfig: state => state.pwaConfig,
    loader: (state) => state.loader,
    compliance: state => state.compliance,
    walletNUBAN: state => state.walletNUBAN,
    partner: state => state.partner,
    insurer: state => state.insurer,
    isLoading: state => state.isLoading
  },
});

export default store;
