
//TODO: remove this function
const money = function (value, currency = null, language = null) {
  if (!currency) {
    currency = 'NGN';
  }

  if (!language) {
    language = navigator.language || 'en-NG';
  }

  return parseFloat(value).toLocaleString(language, {currency: currency, style: 'currency'})
}

const moneyFormat = function (value, currency, language = null) {
    if(!language) {
        language = navigator.language || 'en-NG'; 
    }

    if (!currency) {
      currency = 'NGN';
    }

    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    }).format(value);
  }

export {money, moneyFormat}
