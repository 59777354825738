import widget from "./widget";
import pwa from "./pwa";

const childernRoutes = [
  {
    path: "dashboard",
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    name: "partner.dashboard",
    component: () =>
      import(
        /* webpackChunkName: "partner-dashboard" */ "@/views/partners/Dashboard"
      ),
  },
  {
    path: "products",
    meta: {
      title: "Products",
      requiresAuth: true,
    },
    name: "partner.products",
    component: () =>
      import(
        /* webpackChunkName: "partner-products-list" */ "@/views/partners/products/ListProducts"
      ),
  },
  {
    path: "products/:id",
    meta: {
      title: "Products",
      requiresAuth: true,
    },
    name: "partner.product.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-product-details" */ "@/views/partners/products/ProductDetail"
      ),
  },
  {
    path: "orders",
    meta: {
      title: "Orders",
      requiresAuth: true,
    },
    name: "partner.orders",
    component: () =>
      import(
        /* webpackChunkName: "partner-orders-list" */ "@/views/partners/orders/ListOrders"
      ),
  },
  {
    path: "orders/create",
    meta: {
      title: "Create Order",
      requiresAuth: true,
    },
    name: "partner.orders.create",
    component: () =>
      import(
        /* webpackChunkName: "partner-orders-create" */ "@/views/partners/orders/CreateOrder"
      ),
  },
  {
    path: "orders/:id",
    meta: {
      title: "Order Details",
      requiresAuth: true,
    },
    name: "partner.orders.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-orders-detail" */ "@/views/partners/orders/OrderDetail"
      ),
  },
  {
    path: "customers",
    meta: {
      title: "Customers",
      requiresAuth: true,
    },
    name: "partner.customers",
    component: () =>
      import(
        /* webpackChunkName: "partner-customers-list" */ "@/views/partners/customers/ListCustomers"
      ),
  },
  {
    path: "customers/:c_ref",
    meta: {
      title: "Customers",
      requiresAuth: true,
    },
    name: "partner.customer.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-customer-detail" */ "@/views/partners/customers/CustomerDetail"
      ),
  },
  {
    path: "support",
    meta: {
      title: "Support",
      requiresAuth: true,
    },
    name: "partner.support",
    component: () =>
      import(
        /* webpackChunkName: "partner-support" */ "@/views/partners/help/Support"
      ),
  },
  {
    path: "settings",
    meta: {
      title: "Settings",
      requiresAuth: true,
    },
    name: "partner.settings",
    component: () =>
      import(
        /* webpackChunkName: "partner-settings" */ "@/views/partners/settings/Settings"
      ),
  },
  {
    path: "payments/wallet-transactions",
    meta: {
      title: "Wallet transactions",
      requiresAuth: true,
    },
    name: "partner.payments.wallet-transactions",
    component: () =>
      import(
        /* webpackChunkName: "partner-payments-wallet-transactions" */ "@/views/partners/payments/Transactions"
      ),
  },
  {
    path: "payments/options",
    meta: {
      title: "Payment options",
      requiresAuth: true,
    },
    name: "partner.payments.options",
    component: () =>
      import(
        /* webpackChunkName: "partner-payments-options" */ "@/views/partners/payments/Options"
      ),
  },
  {
    path: "quotes",
    meta: {
      title: "Quotes",
      requiresAuth: true,
    },
    name: "partner.quotes",
    component: () =>
      import(
        /* webpackChunkName: "partner-quotes" */ "@/views/partners/quotes/ListQuotes"
      ),
  },
  {
    path: "quotes/:quote_ref",
    meta: {
      title: "Quote Details",
      requiresAuth: true,
    },
    name: "partner.quote.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-quote-detail" */ "@/views/partners/quotes/Detail"
      ),
  },
  {
    path: "claims",
    meta: {
      title: "Claims",
      requiresAuth: true,
    },
    name: "partner.claims",
    component: () =>
      import(
        /* webpackChunkName: "partner-claims-list" */ "@/views/partners/claims/List"
      ),
  },
  {
    path: "claims/create",
    meta: {
      title: "Claims",
      requiresAuth: true,
    },
    name: "partner.claims.create",
    component: () =>
      import(
        /* webpackChunkName: "partner-claims-create" */ "@/views/partners/claims/Create"
      ),
  },
  {
    path: "claims/:id",
    meta: {
      title: "Claim Details",
      requiresAuth: true,
    },
    name: "partner.claims.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-claims-detail" */ "@/views/partners/claims/Detail"
      ),
  },
  {
    path: "insurance-credit-request",
    meta: {
      title: "Insurance credit request",
      requiresAuth: true,
    },
    name: "partner.credit.request",
    component: () =>
      import(
        /* webpackChunkName: "partner-credit-request" */ "@/views/partners/insuranceCreditRequest/List"
      ),
  },
  {
    path: "credit-notes",
    meta: {
      title: "Credit Notes",
      requiresAuth: true,
    },
    name: "partner.creditNotes",
    component: () =>
      import(
        /* webpackChunkName: "partner-credit-notes" */ "@/views/partners/creditNotes/List"
      ),
  },
  {
    path: "credit-notes/create",
    meta: {
      title: "Create Credit Note",
      requiresAuth: true,
    },
    name: "partner.creditNotes.create",
    component: () =>
      import(
        /* webpackChunkName: "partner-credit-notes-create" */ "@/views/partners/creditNotes/Create"
      ),
  },
  {
    props: true,
    path: "credit-notes/:code",
    name: "partner.creditNotes.detail",
    meta: {
      title: "Credit Note Details",
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "partner-credit-notes-detail" */ "@/views/partners/creditNotes/Detail"
      ),
  },
  {
    path: "debit-notes",
    meta: {
      title: "Debit Notes",
      requiresAuth: true,
    },
    name: "partner.debitNotes",
    component: () =>
      import(
        /* webpackChunkName: "partner-debit-notes" */ "@/views/partners/debitNotes/List"
      ),
  },
  {
    path: "debit-notes/create",
    meta: {
      title: "Create Debit Note",
      requiresAuth: true,
    },
    name: "partner.debitNotes.create",
    component: () =>
      import(
        /* webpackChunkName: "partner-debit-notes-create" */ "@/views/partners/debitNotes/Create"
      ),
  },
  {
    path: "debit-notes/:code",
    meta: {
      title: "Debit Note Detail",
      requiresAuth: true,
    },
    name: "partner.debitNotes.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-debit-notes-detail" */ "@/views/partners/debitNotes/Detail"
      ),
  },
  {
    path: "compliance",
    meta: {
      title: "Compliance",
      requiresAuth: true,
    },
    name: "partner.compliance",
    component: () =>
      import(
        /* webpackChunkName: "partner-compliance" */ "@/views/partners/compliance/Index"
      ),
  },
  {
    path: "compliance/completed",
    meta: {
      title: "Compliance completed",
      requiresAuth: true,
    },
    name: "partner.compliance.completedSla",
    component: () =>
      import(
        /* webpackChunkName: "partner-compliance-completedSla" */ "@/views/partners/compliance/CompletedSla"
      ),
  },
  {
    path: "get-started",
    meta: {
      title: "Get Started",
      requiresAuth: true,
    },
    name: "partner.get-started",
    component: () =>
      import(
        /* webpackChunkName: "partner-get-started" */ "@/views/partners/getStarted/GetStarted"
      ),
  },
  ...widget,
  ...pwa
];

export { childernRoutes as PartnersSubRoutes };
