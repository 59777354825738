import {HTTP} from '@/helpers/https'

export default class Wallet
{
    /**
     * Get a partner's wallets balance.
     * @param {Object} config axios config
     * @returns Promise<AxiosResponse>
     */
    static getBalance(config = {})
    {
        return HTTP.get('/partners/wallet/balance', config)
    }

    /**
     * Get a list of transaction on partner's wallets.
     * @param {Object} config axios config
     * @returns Promise<AxiosResponse>
     */
    static getTransactions(config = {})
    {
        return HTTP.get('partners/wallet/transactions', config)
    }

    static fetchNUBAN(config = {})
    {
        return HTTP.get('partners/wallet/nuban', config);
    }
}