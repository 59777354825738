// insert a language selection dropdown at the bottom of the page which reloads the page with selected language code
const languages = ['en', 'fr', 'ar']
const queryParams = new URLSearchParams(window.location.search)
const currentLanguage =
  queryParams.get('lng') || localStorage.getItem('i18nextLng') || 'en'
const languageSelector = document.createElement('select')

// Function to set the direction based on language
function setDirection(language) {
  if (language === 'ar') {
    document.documentElement.setAttribute('dir', 'rtl')
    document.body.style.textAlign = 'right'
  } else {
    document.documentElement.setAttribute('dir', 'ltr')
    document.body.style.textAlign = 'left'
  }
}

// Call the function initially based on the current language
setDirection(currentLanguage)

languageSelector.style.position = 'fixed'
languageSelector.style.top = '17px'
languageSelector.style.right = '100px'
languageSelector.style.zIndex = '2000'
languageSelector.style.border = '1px solid #000'
languageSelector.style.borderRadius = '5px'
languageSelector.style.backgroundColor = '#fff'
languageSelector.style.cursor = 'pointer'
languageSelector.style.fontFamily = 'Outfit, sans-serif'
languageSelector.style.fontSize = '14px'
languageSelector.style.color = '#000'
languageSelector.style.textAlign = 'center'
languageSelector.style.textTransform = 'uppercase'
languageSelector.style.fontWeight = '500'
languageSelector.style.letterSpacing = '1px'
languageSelector.style.textDecoration = 'none'
languageSelector.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)'
languageSelector.style.transition = 'all 0.3s ease'
languageSelector.style.outline = 'none'
languageSelector.style.appearance = 'none'
languageSelector.style.webkitAppearance = 'none'
languageSelector.style.mozAppearance = 'none'
languageSelector.style.msAppearance = 'none'
languageSelector.style.oAppearance = 'none'
languageSelector.style.webkitAppearance = 'none'

languages.forEach((language) => {
  const option = document.createElement('option')
  option.value = language
  option.innerText = language
  if (language === currentLanguage) {
    option.selected = true
    console.log('selected language', language)
    localStorage.setItem('i18nextLng', language)
  }
  languageSelector.appendChild(option)
})

languageSelector.addEventListener('change', (event) => {
  window.location = `?lng=${event.target.value}`
})

document.body.appendChild(languageSelector)
