<template>

  <div class="__container">
    <div class="loading-container">
      <video autoplay loop muted class="video-loading">
        <source src="./../../assets/loading_animation.mp4" type="video/mp4">
      </video>
    </div>
<!--    <div class="loader">-->
<!--      <div class="cube"></div>-->
<!--      <div class="cube"></div>-->
<!--      <div class="cube"></div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.loader {
  --cube-size: 25px;
  width: calc(var(--cube-size) * 2);
  height: calc(var(--cube-size) * 2);
  position:relative;
}

.loader .cube {
  width: var(--cube-size);
  height: var(--cube-size);
  background: #1A1AFF;
border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-cube-1 2.4s 0.2s cubic-bezier(0.72, 0.01, 0.01, 1) infinite;
}

.loader .cube:nth-of-type(2) {
  background: #5353FD;
  right: 0;
  left: auto;
  animation-name: loader-cube-2;
  animation-delay: 0.4s;
}

.loader .cube:nth-of-type(3) {
  background: #C4C4FF;
  bottom: 0;
  top: auto;
  animation-name: loader-cube-3;
  animation-delay: 0s;
}

@keyframes loader-cube-1 {
  from,
  to {
    transform: translate(0%);
  }
  25% {
    transform: translateY(100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(100%);
  }
}

@keyframes loader-cube-2 {
  from,
  to {
    transform: translate(0);
  }
  25% {
    transform: translate(-100%);
  }
  50% {
    transform: translate(-100%, 100%);
  }
  75% {
    transform: translateY(100%);
  }
}

@keyframes loader-cube-3 {
  from,
  to {
    transform: translate(0);
  }
  25% {
    transform: translate(100%);
  }
  50% {
    transform: translate(100%, -100%);
  }
  75% {
    transform: translateY(-100%);
  }
}
.__container{
    height:100vh;
    position:absolute;
    top:0;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    background: #000005c7;
}
</style>
