import {HTTP} from '@/helpers/https';

export default class NotificationService
{
    /**
     * Get a list of quotes.
     * @param {Object} config axios config object.
     * @returns Promise<AxiosResponse>
     */
    static list(config = {}) {
        return HTTP.get('/notifications', config);
    }

    static markAllAsRead(data = {}, config = {}){
        return HTTP.post('/notifications/read-all', data, config);
    }

    static markAsRead(id, data = {}, config = {}){
        return HTTP.post(`/notifications/${id}/read`, data, config);
    }

    static delete(id, config = {}) {
        return HTTP.delete(`/notifications/${id}`, config);
    }

    static deleteAll(config = {}) {
        return HTTP.delete('/notifications', config);
    }
}
