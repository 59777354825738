import axios from 'axios';
import { TOKEN, USER_DETAIL } from '@/helpers/token';
import User from '@/services/User';
import { isTravelPortal } from '@/router';
import { inTestMode } from '@/services/Env';
import store from '@/store';

// set this header to true to force this request
// to ignore sandbox URL
const LIVE_ONLY_HEADER = 'X-Live-Only';

function configForProduction(config = {}) {
  const headers = {};
  headers[LIVE_ONLY_HEADER] = true;
  config.headers = { ...config.headers, ...headers };

  return config;
}

function isSandboxRequest(config) {
  return (
    inTestMode() &&
    process.env.VUE_APP_SANDBOX_API_URL &&
    !config.headers[LIVE_ONLY_HEADER]
  );
}

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

HTTP.interceptors.request.use(
  (config) => {
    const user = USER_DETAIL();
    const token = TOKEN();
    const partner = store.getters.partner;

    config.headers["X-Channel"] = isTravelPortal()
      ? "Travel Portal"
      : "Dashboard";

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.baseURL = isSandboxRequest(config)
        ? process.env.VUE_APP_SANDBOX_API_URL
        : process.env.VUE_APP_API_URL;
    }

    if (isSandboxRequest(config) && partner) {
      config.headers["X-Partner-Key"] = partner.key;
    } else if (user) {
      config.headers["X-Partner-Key"] = user.owner.key;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    if (response.status >= 300 && response.status < 400) {
      const redirectUrl = response.headers.location;
      // Perform the redirect manually
      window.location.href = redirectUrl;

      return new Promise(() => {});
    }

    return response;
  },
  (error) => {
    if (error.response && [301, 302].includes(error.response.status)) {
      const redirectUrl = error.response.headers.location;
      // Perform the redirect manually
      window.location.href = redirectUrl;
    }

    if (error.response) {
      if (error.response.status == 401) {
        User.logout();
      }
    }
    return Promise.reject(error);
  }
);

export { HTTP, LIVE_ONLY_HEADER, configForProduction };

class Downloader {
  /**
   *
   * @param {String} url the download address
   * @param {String} fileName the name to save the downloaded file with
   * @param {Object} config config for the axios client
   * @returns Promise
   */
  static get(url, fileName = "", config = {}) {
    return new Promise((res, rej) => {
      HTTP.get(url, { responseType: "blob", ...config })
        .then(({ data }) => {
          if (fileName) {
            Downloader.save(data, fileName);
          }
          res(data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  /**
   *
   * @param {Blob} file blob object containing the file
   * @param {String} fileName the name to save the with
   * @returns void
   */
  static save(file, fileName) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([file]));
    link.target = "_blank";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export { Downloader };
