const childernRoutes = [
  {
    path: "dashboard",
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    name: "insurer.dashboard",
    component: () =>
      import(
        /* webpackChunkName: "insurer-dashboard" */ "@/views/insurers/Dashboard"
      ),

  },
  {
    path: "products",
    meta: {
      title: "Products",
      requiresAuth: true,
    },
    name: "insurer.products",
    component: () =>
      import(
        /* webpackChunkName: "insurer-products-list" */ "@/views/insurers/products/ListProducts"
      ),
  },
  {
    path: "products/:id",
    meta: {
      title: "Product Detail",
      requiresAuth: true,
    },
    name: "insurer.product.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "insurer-product-detail" */ "@/views/insurers/products/detail/Index"
      ),
  },
  {
    path: "policies",
    meta: {
      title: "Policies",
      requiresAuth: true,
    },
    name: "insurer.policies",
    component: () =>
      import(
        /* webpackChunkName: "insurer-policy-list" */ "@/views/insurers/policies"
      ),
  },
  {
    path: "customers",
    meta: {
      title: "Customers",
      requiresAuth: true,
    },
    name: "insurer.customers",
    component: () =>
      import(
        /* webpackChunkName: "insurer-customers-list" */ "@/views/insurers/customers/ListCustomers"
        ),
  },
  {
    path: "customers/request/:id",
    meta: {
      title: "Customers Request",
      requiresAuth: true,
    },
    name: "insurer.customer.details",
    component: () =>
      import(
        /* webpackChunkName: "insurer-customers-request" */ "@/views/insurers/customers/CustomerDetails"
        ),
  },
  {
    path: "policies/request/:id",
    meta: {
      title: "Policy Request",
      requiresAuth: true,
    },
    name: "insurer.policies.request",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "insurer-policy-request" */ "@/views/insurers/policies/PolicyRequestDetail"
      ),
  },
  {
    path: "claims",
    meta: {
      title: "Claims",
      requiresAuth: true,
    },
    name: "insurer.claims",
    component: () =>
      import(
        /* webpackChunkName: "insurer-claims-list" */ "@/views/insurers/claims"
      ),
  },
  {
    path: "claims/request/:id",
    meta: {
      title: "Claims Request",
      requiresAuth: true,
    },
    name: "insurer.claims.request",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "insurer-claims-request" */ "@/views/insurers/claims/ClaimsRequestDetail"
      ),
  },
  {
    path: "claims/history/:id",
    meta: {
      title: "Claims History",
      requiresAuth: true,
    },
    name: "insurer.claims.history",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "insurer-claims-history" */ "@/views/insurers/claims/ClaimsHistoryDetail"
      ),
  },
  {
    path: "orders",
    meta: {
      title: "Orders",
      requiresAuth: true,
    },
    name: "insurer.orders",
    component: () =>
      import(
        /* webpackChunkName: "insurer-orders-list" */ "@/views/insurers/orders/ListOrders"
      ),
  },
  {
    path: "orders/:id",
    meta: {
      title: "Order Detail",
      requiresAuth: true,
    },
    name: "insurer.order.detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "insurer-order-detail" */ "@/views/insurers/orders/OrderDetail"
      ),
  },
  {
    path:"transactions",
    meta:{
      title:'Transactions',
      requiresAuth:true
    },
    name:"insurer.transactions",
    props:true,
    component:() =>
      import(
        /* webpackChunkName: "insurer-transactions-list" */ "@/views/insurers/transactions"
        ),
  },
  {
    path:"reports",
    meta:{
      title:'Reports',
      requiresAuth:true
    },
    name:"insurer.reports",
    props:true,
    component:() =>
    import(
      /* webpackChunkName: "insurer-order-detail" */ "@/views/insurers/reports"
    ),
  }
];

export { childernRoutes as InsurersSubRoutes };
