const TOKEN = () => {
    return localStorage.getItem('grow_token');
}

const USER_DETAIL = () => {
    return JSON.parse(localStorage.getItem('grow_user'));
};


export {TOKEN, USER_DETAIL}
