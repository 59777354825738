export default [
  {
    path: "widget/TermsAndConditions",
    meta: {
      title: "Widget Terms and Conditions",
      requiresAuth: true,
    },
    name: "partner.widget",
    component: () =>
      import(
        /* webpackChunkName: "partner-widget-terms" */ "@/views/partners/apps/widget/TermsAndConditions"
      ),
  },
  {
    path: "widget/ProductType",
    meta: {
      title: "Product Type",
      requiresAuth: true,
    },
    name: "partner.widget.product-type",
    component: () =>
      import(
        /* webpackChunkName: "partner-widget-product-type" */ "@/views/partners/apps/widget/ProductType"
      ),
  },
  {
    path: "widget/PurchaseInsurance",
    meta: {
      title: "Purchase Insurance",
      requiresAuth: true,
    },
    name: "partner.widget.purchase-insurance",
    component: () =>
      import(
        /* webpackChunkName: "partner-widget-purchase-insurance" */ "@/views/partners/apps/widget/PurchaseInsurance"
      ),
  },
  {
    path: "widget/Success",
    meta: {
      title: "Success",
      requiresAuth: true,
    },
    name: "partner.widget.success",
    component: () =>
      import(
        /* webpackChunkName: "partner-widget-success" */ "@/views/partners/apps/widget/Success"
     ),
  },
  {
    path: "widget/view",
    meta: {
      title: "View Widget",
      requiresAuth: true,
    },
    name: "partner.widget.view",
    component: () =>
      import(
        /* webpackChunkName: "partner-widget-view" */ "@/views/partners/apps/widget/ViewWidget"
      ),
  }
]
