
import UserService from "./User";

function getBaseUrl() {
  return inTestMode() && process.env.VUE_APP_SANDBOX_API_URL
    ? process.env.VUE_APP_SANDBOX_API_URL
    : process.env.VUE_APP_API_URL;
}

function switchToLive() {
  localStorage.setItem('liveMode', 1)
  UserService.clearEnvData();
  location.reload();
}

function switchToTest() {
  localStorage.setItem('liveMode', 0)
  UserService.clearEnvData();
  location.reload();
}

function inTestMode() {
  const mode = localStorage.getItem('liveMode');
  return mode == '0' || mode == null
}

function inLiveMode() {
  return !inTestMode();
}

export {
  switchToLive, switchToTest, inTestMode, inLiveMode, getBaseUrl
}

export default {
  switchToLive, switchToTest, inTestMode, inLiveMode, getBaseUrl
}
