import {HTTP} from "@/helpers/https";
import store from "@/store";

/**
 * 
 * @class QuoteService
 */
export default class PartnerService 
{
  static async update(partnerData, config = {}) {
    const {data: partner} = await HTTP.put(`/partners`, partnerData, config);
    store.commit('setPartner', partner.data);
    return partner.data;
  }

  static async getInfo() {
    const {data: partner} = await HTTP.get('/partners/me');
    store.commit('setPartner', partner.data);
    return partner.data;
  }
}
