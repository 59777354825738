import {HTTP, Downloader, configForProduction} from "@/helpers/https";

export default class Compliance {

  static updatePersonalInfo(data, config = {}) {
    
    return HTTP.post('/partners/compliance/sections/personal', data, configForProduction(config));
  }

  static updateBusinessInfo(data, config = {}) {
    return HTTP.post('/partners/compliance/sections/business', data, configForProduction(config));
  }

  static getCompliance(config = {}) {
    return HTTP.get('/partners/compliance', configForProduction(config));
  }

  static updateSlaInfo(data, config = {}){
    return HTTP.post('/partners/compliance/sections/sla', data, configForProduction(config));
  }

  static getCuracelInfo(config = {}){
    return HTTP.get('/curacel/sla-info',  configForProduction(config))
  }

  static signSla(data, config = {}) {
    return HTTP.post('/partners/compliance/sign-sla', data, configForProduction(config))
  }

  static downloadSla(fileName, config) {
    return Downloader.get(`partners/compliance/sla`, fileName, configForProduction(config))
  }
}