export default [
  {
    path: "pwa/theme",
    meta: {
      title: "PWA Theme",
      requiresAuth: true,
    },
    name: "partner.pwa",
    component: () =>
      import(
        /* webpackChunkName: "partner-pwa-theme" */ "@/views/partners/apps/pwa/Theme"
      ),
  },
  {
    path: "pwa/product-type",
    meta: {
      title: "PWA Available Product Type",
      requiresAuth: true,
    },
    name: "partner.pwa.productType",
    component: () =>
      import(
        /* webpackChunkName: "partner-pwa-productType" */ "@/views/partners/apps/pwa/ProductType"
      ),
  },
  {
    path: "pwa/payment-gateway",
    meta: {
      title: "PWA Available Product Type",
      requiresAuth: true,
    },
    name: "partner.pwa.paymentGateway",
    component: () =>
      import(
        /* webpackChunkName: "partner-pwa-paymentGateway" */ "@/views/partners/apps/pwa/PaymentGateway"
      ),
  },
  {
    path: "pwa/view",
    meta: {
      title: "View PWA",
      requiresAuth: true,
    },
    name: "partner.pwa.view",
    component: () =>
      import(
        /* webpackChunkName: "partner-pwa-viewApp" */ "@/views/partners/apps/pwa/ViewApp"
      ),
  }
]
