import { HTTP } from '@/helpers/https';
import User from '@/services/User'

export default [
  {
    path: '/',
    name: 'travel.landing',
    meta: {
      title: 'Buy Travel Insurance',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "travel-landing-landing" */ "@/views/travel/landing/Landing")
  },
  {
    path: 'policies',
    name: 'travel.policies',
    meta: {
      title: 'Travel Policies',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "travel-policies-policies" */ "@/views/travel/policies/Policies")
  },
  {
    path: 'policies/complete',
    name: 'travel.policies.complete',
    meta: {
      title: 'Complete your purchase',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "travel-policies-complete" */ "@/views/travel/complete/CompleteOrder")
  },
  {
    path: 'transactions',
    name: 'travel.transactions',
    meta: {
      title: 'Travel Transactions',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "travel-transactions-transactions" */ "@/views/travel/transactions/Transactions")
  },
  {
    path: 'l/:linkId',
    name: 'travel.link',
    meta: {
      title: 'Buy Travel Insurance',
      requiresAuth: false
    },
    beforeEnter: async (to, from, next) => {
      User.logoutFromApp();
      try {
        const resp = await HTTP.post(`/travel/l/${to.params.linkId}`);
        User.loginToApp(resp.data.user, resp.data.token, true, to.params.linkId);
        await next();
      } catch (e) {
        User.logoutFromApp();
        next({name:'travel.404'})
      }
    },
    component: () => import(/* webpackChunkName: "travel-landing-landing" */ "@/views/travel/landing/Landing")
  },
  {
    path: '404',
    name: 'travel.404',
    meta: {
      title: '404',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "travel-landing-404" */ "@/views/travel/404")
  }
];
