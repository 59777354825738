import { HTTP, configForProduction } from "@/helpers/https";
import router from "@/router";
import store from "@/store";

/**
 * Use this service to interact with quotes from the API
 * @class QuoteService
 */
export default class UserService {
  /**
   * Get currently logged in user.
   * @returns Promise<AxiosResponse>
   */
  static activeUser(config = {}) {
    return HTTP.get(`/user`, configForProduction(config));
  }

  static async userDetail() {
    const { data } = await this.activeUser();
    localStorage.setItem("grow_user", JSON.stringify(data));
    store.commit("setUser", data);
    return data;
  }

  static logout() {
    UserService.logoutFromApp();
    router.push({ name: "auth.sign-in", params: { from_logout: "true" } });
  }

  static async update(userData, config = {}) {
    const user = store.getters.user;
    const { data } = await HTTP.put(
      `/user/${user.id}`,
      userData,
      configForProduction(config)
    );
    store.commit("setUser", data.data);
    localStorage.setItem("grow_user", JSON.stringify(data.data));
    return data;
  }

  static async updatePassword(passwordData, config = {}) {
    const user = store.getters.user;
    const { data } = await HTTP.put(
      `/user/${user.id}/password`,
      passwordData,
      configForProduction(config)
    );
    return data;
  }

  /**
   * Login user to store. No http request is made.
   * @param {Object} user - user data
   * @param {String} token - token to be used for authentication
   */
  static loginToApp(user, token, viaTravelLink = false, linkId = null) {
    localStorage.setItem("grow_user", JSON.stringify(user));
    localStorage.setItem("grow_token", token);
    localStorage.setItem("viaTravelLink", viaTravelLink ? 1 : 0);
    if (viaTravelLink) {
      localStorage.setItem("linkId", linkId);
    }
    store.commit("setUser", user);
  }

  static async register(userData) {
    const { data } = await HTTP.post(`/user/register`, userData);
    UserService.loginToApp(data.data.user, data.data.token);
    return data;
  }

  static authenticate(credentials) {
    return HTTP.post(`/user/login`, credentials);
  }

  static async authenticateWithSSO() {
    return await HTTP.get("/auth/redirect", {
      baseURL: process.env.VUE_APP_API_URL?.endsWith("/api/v1")
        ? process.env.VUE_APP_API_URL?.replace("/api/v1", "")
        : process.env.VUE_APP_API_URL?.replace("/api", ""),
      validateStatus: (status) => status >= 200 && status < 400,
    });
  }

  /**
   * Delete user info from store. No redirect is made.
   */
  static logoutFromApp() {
    store.commit("setUser", {});
    localStorage.removeItem("grow_token");
    this.clearEnvData();
  }

  static clearEnvData() {
    localStorage.removeItem("grow_user");
    localStorage.removeItem("vuex");
    localStorage.removeItem("viaTravelLink");
    localStorage.removeItem("linkId");
  }

  static getDashboardRoute(user) {
    let route = false;

    if (user.is_insurer) {
      route = { name: "insurer" };
    } else if (user.is_partner) {
      if (this.isViaTravelLink()) {
        route = { name: "travel.link", params: { linkId: this.getLinkId() } };
      } else if (user.owner.is_travel_agent) {
        route = { name: "travel.landing" };
      } else {
        route = { name: "partner" };
      }
    }
    return route;
  }

  static isViaTravelLink() {
    return +(localStorage.getItem("viaTravelLink") || "0");
  }

  static getLinkId() {
    return localStorage.getItem("linkId");
  }
}
