import {HTTP} from "@/helpers/https";
import store from "@/store";

let getInsurerProducts = (filters) => {
    return HTTP.get(`insurers/products`, {params: filters});
}

let archiveProduct = (id) => {
    return HTTP.post(`/products/${id}/archive`);
}

let restoreArchivedProduct = (id) => {
    return HTTP.post(`/products/${id}/archive?restore=1`);
}

let getSingleInsurerProduct = (id) => {
    return HTTP.get(`/insurers/products/${id}`);
}

let deleteInsurerProduct = (id) => {
    return HTTP.delete(`/products/${id}`);
}

let addProduct = (data) => {
    return HTTP.post(`/products`, data);
}

let updateProduct = (data, id) => {
    return HTTP.put(`/products/${id}`, data);
}

let getInsurerStatistics = () => {
    return HTTP.get(`/insurers/stats`);
}
let getProductReport = (filters) => {
    return HTTP.get(`products/insurer-analytics`, {params: filters})
}
let getPoliciesReport = (filters) => {
    return HTTP.get(`policies/insurer-analytics`, {params:filters})
}
let getClaimsReport = (filters) => {
    return HTTP.get(`claims/insurer-analytics`, {params:filters})
}
let getCustomerReport = (filters) => {
    return HTTP.get(`customers/insurer-analytics`, {params:filters})
}

let getInfo = async () => {
    const resp = await HTTP.get(`/insurers/info`);
    store.commit('setInsurer', resp.data.data);
    return resp;
}

const InsurerService = {
    getInsurerProducts,
    archiveProduct,
    restoreArchivedProduct,
    getSingleInsurerProduct,
    deleteInsurerProduct,
    addProduct,
    updateProduct,
    getInsurerStatistics,
    getProductReport,
    getPoliciesReport,
    getClaimsReport,
    getCustomerReport,
    getInfo
};

export {InsurerService}
