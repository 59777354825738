import {extend, localize} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

localize('en', en);
extend('confirmData', {
    message: 'You need to confirm that customer data is accurate.',
    validate: value => !!value,
    computesRequired: true,
});

extend('terms&condi', {
    message: 'You need to agree to the terms and condition to proceed.',
    validate: value => !!value,
    computesRequired: true,
});

extend('array', {
    message: field => `The ${field} field is required.`,
    validate: value => {
        return !!(value instanceof Array && value.length);
    },
    computesRequired: true,
});

extend('url', {
    message: field => `The ${field} field must be a valid URL (https://example.com).`,
    validate: value => {
        // eslint-disable-next-line no-useless-escape
        return /^(?:https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value);
    }
})

extend('numbers', {
    message: field => `The ${field} field must be a number.`,
    validate: value => {
        return !isNaN(value);
    },
    computesRequired: true,
})

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});