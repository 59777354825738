import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { money } from "./helpers/money";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueClipboard from "vue-clipboard2";
import Paystack from "vue-paystack";
import VueCurrencyInput from "vue-currency-input";
import DateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";
import Flutterwave from "flutterwave-vue-v3";
import VModal from "vue-js-modal";
import VuePhoneNumberInput from "vue-phone-number-input";
import * as Sentry from "@sentry/vue";
import Env from '@/services/Env'
import '@/helpers/language-picker'


import "@/assets/scss/main.scss";
import "vue-select/dist/vue-select.css";
import "@/assets/css/shared-styles.css";
import "@/assets/css/helpers.css";
import "remixicon/fonts/remixicon.css";
import "./vee-validate";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VModal);


Vue.use(VueCurrencyInput, {
  globalOptions: { currency: "USD" },
});

Vue.use(Flutterwave, {
  publicKey: Env.inTestMode() && process.env.VUE_APP_FLUTTERWAVE_TEST_KEY
    ? process.env.VUE_APP_FLUTTERWAVE_TEST_KEY
    : process.env.VUE_APP_FLUTTERWAVE_PUB_KEY,
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("PaystackBtn", Paystack);
Vue.component("DateTimePicker", DateTimePicker);
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    money(value, currency, language) {
      return money(value, currency, language);
    },
    ucfirst(value) {
      return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
    },
  },
  data: () => ({ moment }),
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/grow\.curacel\.co/, /^https:\/\/dev\.grow\.curacel\.co/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
