import Vue from 'vue';
import VueRouter from 'vue-router';
import { InsurersSubRoutes } from '@/views/insurers/routes';
import { PartnersSubRoutes } from '@/views/partners/routes';
import { AuthRoutes } from '@/views/auth/routes';
import TravelRoutes from '@/views/travel/routes';
import store from '@/store';
import UserService from '@/services/User';
import PartnerService from '@/services/Partner';
import { InsurerService } from '@/services/Insurer';

Vue.use(VueRouter);

const home = {
  path: '/',
  name: 'home',
};

if (isTravelPortal(true)) {
  home.component = () =>
    import(/* webpackChunkName: 'travel' */ '@/views/travel/Home');
  home.children = TravelRoutes;
  home.meta = {
    requiresAuth: false,
  };
}

const router = new VueRouter({
  routes: [
    home,
    {
      path: '/app',
      name: 'app',
      component: () => import('@/views/auth/Home'),
      redirect: '/app/sign-in',
      children: AuthRoutes,
    },
    {
      path: '/insurer',
      name: 'insurer',
      component: () => import('@/views/insurers/Home'),
      redirect: '/insurer/dashboard',
      meta: { requiresAuth: true },
      children: InsurersSubRoutes,
    },
    {
      path: '/partner',
      name: 'partner',
      component: () => import('@/views/partners/Home'),
      redirect: '/partner/dashboard',
      beforeEnter: (to, from, next) => {
        // ...
        // eslint-disable-next-line no-undef
        store.commit('setLoader', false);
        next();
      },
      meta: { requiresAuth: true },
      children: PartnersSubRoutes,
    },
    {
      path: '/travel',
      name: 'travel',
      component: () =>
        import(/* webpackChunkName: 'travel' */ '@/views/travel/Home'),
      children: TravelRoutes,
    },
    {
      path: '/payments/success',
      name: 'payments.success',
      component: () =>
        import(
          /* webpackChunkName: 'payments.success' */ '@/views/payments/Success'
        ),
    },
    {
      path: '/app/sla',
      name: 'app.sla',
      component: () => import(/* webpackChunkName: 'app.sla' */ '@/views/SLA'),
    },
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ],
});

router.beforeEach(async (to, from, next) => {
  store.commit('setLoader', true);
  store.commit('setLoaderState', true);

  // on page reload, fetch the user from the server
  if (!from.name && to.meta && to.meta.requiresAuth) {
    try {
      await Promise.all([
        UserService.userDetail(),
        PartnerService.getInfo(),
        InsurerService.getInfo(),
      ]);
      next();
    } catch (e) {
      next();
    }
  }

  // if the user is not logged in and is visting a user only page, redirect to the login page
  else if (to.meta && to.meta.requiresAuth && !store.getters.user.id) {
    next({ name: 'auth.sign-in' });
  }

  // Home page for none travel portal currently has no view.
  // So if the user is logged in, redirect to their dashboard.
  // Otherwise, continue to login page.
  else if (to.name == 'home' && !isTravelPortal()) {
    if (store.getters.user.id) {
      next(UserService.getDashboardRoute(store.getters.user));
    } else {
      next({ name: 'auth.sign-in' });
    }
  }

  // Redirect logged in users away from guest-only (mostly authentication) pages.
  else if (to.meta && to.meta.guestOnly && store.getters.user.id) {
    next(UserService.getDashboardRoute(store.getters.user));
  } else {
    next();
  }
});

router.afterEach((to) => {
  store.commit('setLoaderState', false);
  document.title =
    to.meta && to.meta.title
      ? to.meta.title + ' - Curacel Grow'
      : 'Curacel Grow';
});

function isTravelPortal(viaSubdomain = false) {
  if (window.location.hostname.split('.').indexOf('travel') !== -1) {
    return true;
  }

  if (!viaSubdomain && window.location.href.includes('#')) {
    const fragments = window.location.href.split('/');
    return fragments[fragments.indexOf('#') + 1] === 'travel';
  }

  return false;
}

export default router;

export { router, isTravelPortal };
