import {HTTP} from "@/helpers/https";

function saveWidgetInfo(widget){
  return HTTP.post(`widget/settings`, widget);
}

function fetchWidgetInfo() {
  return HTTP.get('widget/settings');
}

function fetchPwaInfo() {
  return HTTP.get('pwa/settings');
}

function savePwaInfo(pwa){
  return HTTP.post(`pwa/settings`, pwa);
}

function uploadLogo(logo){
  return HTTP.post(`pwa/logo`, logo);
}

export {
  saveWidgetInfo, fetchWidgetInfo, fetchPwaInfo, savePwaInfo, uploadLogo
}
